import React, { useState } from "react";
import authApi from "./auth-api";
import { userActions, apiList, methodType } from "./constant";
import { useAppStateProvider } from "./AppStateProvider";
import { useMsal } from "@azure/msal-react";
import getToken from "../util/tokenGenerator";

const InfoContext = React.createContext();

const getData = async ({
  body = {},
  url = "",
  method = methodType.post,
  tokenhandler = () => null,
  isSecured = false,
}) => {
  let response = await authApi({ body, method, url, tokenhandler, isSecured });
  return response;
};

const handleAppState = async (handler) => {
  await handler({ type: userActions.fetchUserInfo, body: {} });
};

function InfoProvider({ children }) {
  const { instance, inProgress, accounts } = useMsal();
  const [userInfo, setUserInfo] = useState({});
  const [callStatus, setCallStatus] = useState({ type: "", status: null });
  const [apiStatus, setApiStatus] = useState({ type: "", val: {} });
  const {
    appState = {},
    updateAppState,
    updateAppStateLocal,
  } = useAppStateProvider();

  const dispatch = async (payload) => {
    const { type = "", body = {}, postCallHandler = () => null } = payload;
    let url = "";
    const origin = "settings";
    setCallStatus({ type, status: null });
    setApiStatus({ type, val: {} });
    const nonce = "abcd1234";
    let response;
    const { uid = "", profile = {} } = appState;
    const { userEmail = null } = profile;
    switch (type) {
      case userActions.updateUserInfo: {
        url = apiList[userActions.updateUserInfo];
        updateAppStateLocal({ ...appState, isLoading: true });
        response = await getData({
          body: { ...body, user_id: uid, origin },
          method: methodType.post,
          url,
          tokenhandler: () => getToken(instance, inProgress, accounts),
          isSecured: true,
        });
        setUserInfo({ ...userInfo });
        if (response.data.success) {
          await handleAppState(updateAppState);
          setCallStatus({ ...callStatus, type, status: true });
          postCallHandler();
          updateAppStateLocal({ isLoading: false });
        } else {
          updateAppStateLocal({ isLoading: false });
          setCallStatus({ ...callStatus, type, status: false });
        }

        break;
      }
      case userActions.sendMfaCode: {
        url = apiList[userActions.sendMfaCode];
        response = await getData({
          body: { ...body, user_id: uid, nonce, origin },
          method: methodType.post,
          url,
          tokenhandler: () => getToken(instance, inProgress, accounts),
          isSecured: true,
        });
        setUserInfo({ ...userInfo });
        if (response.data.success) {
          const { data = {} } = response;
          const { mfa_token = "" } = data;
          await handleAppState(updateAppState);
          setCallStatus({ ...callStatus, type, status: true });
          setApiStatus({ type, val: { mfa_token } });
          postCallHandler();
        } else {
          setCallStatus({ ...callStatus, type, status: false });
        }
        break;
      }
      case userActions.sendTotpInfo: {
        url = apiList[userActions.sendTotpInfo];
        setUserInfo({ ...userInfo, securityInfo: {} });
        response = await getData({
          body: { ...body, userId: uid, nonce, origin },
          method: methodType.post,
          url,
          tokenhandler: () => getToken(instance, inProgress, accounts),
          isSecured: true,
        });
        if (response.data.success) {
          setCallStatus({ ...callStatus, type, status: true });
          setUserInfo({ ...userInfo, securityInfo: response.data });
          postCallHandler();
        } else {
          setCallStatus({ ...callStatus, type, status: false });
        }

        break;
      }
      case userActions.verifyTotpCode: {
        url = apiList[userActions.verifyTotpCode];
        const { val = {} } = apiStatus;
        const { mfa_token = "" } = val;
        const initResponse = await getData({
          url: apiList[userActions.totpInitCall],
          body: { userId: uid, nonce, origin },
          tokenhandler: () => getToken(instance, inProgress, accounts),
          isSecured: true,
        });
        if (initResponse?.data?.success) {
          const { tag = "" } = initResponse?.data;
          response = await getData({
            body: { ...body, userId: uid, nonce, mfa_token, tag, origin },
            method: methodType.post,
            url,
            tokenhandler: () => getToken(instance, inProgress, accounts),
            isSecured: true,
          });
          if (response.data.success) {
            setCallStatus({ ...callStatus, type, status: true });
            await handleAppState(updateAppState);
            postCallHandler("totp");
          } else {
            setCallStatus({ ...callStatus, type, status: false });
          }
        }

        setUserInfo({ ...userInfo });
        break;
      }
      case userActions.verifyMfaCode: {
        url = apiList[userActions.verifyMfaCode];
        const { val = {} } = apiStatus;
        const { mfa_token = "" } = val;
        response = await getData({
          body: { ...body, user_id: uid, nonce, mfa_token, origin },
          method: methodType.post,
          url,
          tokenhandler: () => getToken(instance, inProgress, accounts),
          isSecured: true,
        });
        if (response.data.success) {
          await handleAppState(updateAppState);
          setCallStatus({ ...callStatus, type, status: true });
          postCallHandler("phone");
        } else {
          setCallStatus({ ...callStatus, type, status: false });
        }
        setUserInfo(response);
        break;
      }
      case userActions.resetTotp: {
        url = apiList[userActions.resetTotp];
        response = await getData({
          body: { ...body, userId: uid, nonce, origin },
          method: methodType.post,
          url,
          tokenhandler: () => getToken(instance, inProgress, accounts),
          isSecured: true,
        });
        if (response.data.success) {
          await handleAppState(updateAppState);
          setCallStatus({ ...callStatus, type, status: true });
          postCallHandler();
        } else {
          setCallStatus({ ...callStatus, type, status: false });
        }
        setUserInfo(response);
        break;
      }
      case userActions.auditLog: {
        url = apiList[userActions.auditLog];
        await getData({
          body: {
            ...body,
            userId: uid,
            userEmail: userEmail,
            auditEvent: "landingPageAccess",
            origin,
          },
          method: methodType.post,
          url,
          tokenhandler: () => getToken(instance, inProgress, accounts),
          isSecured: true,
        });
        break;
      }
      case userActions.disableMFA: {
        url = apiList[userActions.disableMFA];
        await getData({
          body: {
            ...body
          },
          method: methodType.post,
          url,
          tokenhandler: () => getToken(instance, inProgress, accounts),
          isSecured: true,
        });
        break;
      }
      default:
        break;
    }
  };

  const value = { userInfo, callStatus, dispatch };
  return <InfoContext.Provider value={value}>{children}</InfoContext.Provider>;
}

function useInfoProvider() {
  const context = React.useContext(InfoContext);
  if (context === undefined) {
    throw new Error("InfoContext must be used within a InfoProvider");
  }
  return context;
}

export { InfoProvider, InfoContext, useInfoProvider };
