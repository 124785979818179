
const lang = window.localStorage.getItem('lang');

const MgoUrlList = [
  `https://go-dev1.moneris.io/${lang}/login`,
  `https://go-dev2.moneris.io/${lang}/login`,
  `https://go-dev3.moneris.io/${lang}/login`,
  `https://go-dev4.moneris.io/${lang}/login`,
  `https://go-cert.moneris.io/${lang}/login`,
  `https://go-preview.moneris.io/${lang}/login`,
  `https://goretail-qa.moneris.io/${lang}/login`,
  `https://ct.monerisgo.com/${lang}/login`
];

export default MgoUrlList;
